<template>
  <div class="">
    <el-dialog
      :title="$t('replyDialog.title')"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      center
    >
      <div style="height: 500px; overflow: auto">
        <el-timeline size="normal">
          <el-timeline-item
            :timestamp="item.created_at"
            placement="top"
            v-for="(item, index) in replyData.list"
            :key="index"
          >
            <el-card>
              <h4>{{ item.reply }}</h4>
              <el-image
                v-for="(i, ind) in item.imgUrl"
                :key="ind"
                :src="i"
                :preview-src-list="[i]"
                style="width: 50px; height: 50px"
              >
              </el-image>
              <div v-if="item.videoUrl">
                <video width="100" height="180" controls>
                  <source :src="item.videoUrl" type="video/mp4" />
                 {{ $t('replyDialog.noUseVideo') }}
                </video>
              </div>
              <p>
                {{ item.is_user ? $t('replyDialog.user') : "CSKH" }}{{$t('replyDialog.send')}} {{ item.created_at }}
              </p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hanldClick()">{{$t('replyDialog.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      replyData: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    hanldClick() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
  .el-timeline{
    padding: 0;
  }
}
</style>
