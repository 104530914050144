<template>
  <div>
    <div class="headerRight" v-if="true || $pathName === 'mgolgames'">
      <img
        class="headerRightImg"
        src="@/assets/earth.png"
        alt=""
        srcset=""
        @click="openDialog()"
      />
      <el-select
        v-model="lang"
        @change="changeLang"
        placeholder="Choose Language"
        v-if="!$isMobile"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-dialog
      :visible.sync="showDialog"
      :width="$isMobile ? '90%' : '500px'"
      center
      :show-close="false"
    >
      <div class="language_list">
        <div
          class="language_item"
          v-for="(item, index) in options"
          :key="index"
          @click="changeLang(item.value)"
          :style="{ color: item.value == lang ? 'blue' : '' }"
        >
          {{ item.label }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "VI",
      showLang: false,
      showDialog: false,
      options: [
        {
          value: "VI",
          label: "Tiếng Việt",
        },
        {
          value: "TH",
          label: "แบบไทย",
        },
        {
          value: "ID",
          label: "Indonesia",
        },
        {
          value: "EN",
          label: "English",
        },
        {
          value: "ZH",
          label: "中文",
        },
      ],
    };
  },
  created() {
    // this.changeLang()
    this.lang = localStorage.getItem("locale") || "VI";
  },
  methods: {
    changeLang(e) {
      this.lang = localStorage.getItem("locale") || "VI";
      if (this.lang === "VI") {
        this.lang = e;
        this.$i18n.locale = e;
      } else {
        this.lang = e;
        this.$i18n.locale = e;
      }
      localStorage.setItem("locale", this.lang);
      this.$store.state.lang = this.lang;
      this.showDialog = false;
      // location.reload();
    },
    openDialog() {
      if (!this.$isMobile) {
        return;
      }
      this.showDialog = true;
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}
.language_item{
  height: 60px;
  line-height: 60px;
}
.headerRight {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 10px;
  height: 100%;
}
.headerRightImg {
  width: 50px !important;
}
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
.language_list {
  text-align: center;
}
.language_item {
  cursor: pointer;
}
.language_item:hover {
  transform: scale(1.1);
}
</style>