<template>
  <el-row class="container">
    <el-col :span="24" class="header">
      <el-col
        :span="10"
        class="logo"
        :class="collapsed ? 'logo-collapse-width' : 'logo-width'"
        v-if="!$isMobile"
      >
        {{ collapsed ? "" : sysName }}
      </el-col>
      <el-col :span="4">
        <div class="tools" @click.prevent="collapse">
          <i class="fa fa-align-justify"></i>
        </div>
      </el-col>
      <!-- <el-col :span="16" class="userinfo">
        <ChangeLang />
      </el-col> -->
      <el-col :span="16" class="userinfo">
        <ChangeLang />
        <el-dropdown trigger="hover">
          <span class="el-dropdown-link userinfo-inner">
            <img src="@/assets/user.png" />
            <span class="sysUserName">{{ sysUserName }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item>我的消息</el-dropdown-item>
            <el-dropdown-item>设置</el-dropdown-item>
            <el-dropdown-item divided @click.native="logout"
              >退出登录</el-dropdown-item
            > -->
            <el-dropdown-item @click.native="logout">{{
              $t("Home.exit")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-col>
    <el-col :span="24" class="main">
      <aside
        :class="
          collapsed ? ($isMobile ? 'menu-collapsed' : '') : 'menu-expanded'
        "
      >
        <!--导航菜单-->
        <el-menu
          id="menuClass"
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          @open="handleopen"
          @close="handleclose"
          @select="handleselect"
          unique-opened
          router
          v-show="!collapsed"
        >
          <template v-for="(item, index) in $router.options.routes">
            <template v-if="!item.hidden">
              <el-submenu :index="index + ''" v-if="!item.leaf" :key="index">
                <template slot="title">
                  <i :class="item.iconCls"></i>
                  {{ $t(item.name) }}
                </template>
                <template v-for="child in item.children">
                  <el-menu-item
                    :index="child.path"
                    :key="child.path"
                    v-if="!child.hidden"
                  >
                    {{ $t(child.name) }}
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item
                v-if="item.leaf && item.children.length > 0"
                :key="index"
                :index="item.children[0].path"
              >
                <i :class="item.iconCls"></i>
                {{ item.children[0].name }}
              </el-menu-item>
            </template>
          </template>
        </el-menu>
        <!--导航菜单-折叠后-->
        <ul
          class="el-menu el-menu-vertical-demo collapsed"
          v-show="collapsed && !$isMobile"
          ref="menuCollapsed"
        >
          <template v-for="(item, index) in $router.options.routes">
            <li :key="index" v-if="!item.hidden" class="el-submenu item">
              <template v-if="!item.leaf">
                <div
                  class="el-submenu__title"
                  style="padding-left: 20px"
                  @mouseover="showMenu(index, true)"
                  @mouseout="showMenu(index, false)"
                >
                  <i :class="item.iconCls"></i>
                </div>
                <ul
                  class="el-menu submenu"
                  :class="'submenu-hook-' + index"
                  @mouseover="showMenu(index, true)"
                  @mouseout="showMenu(index, false)"
                >
                  <template v-for="child in item.children">
                    <li
                      v-if="!child.hidden"
                      :key="child.path"
                      class="el-menu-item"
                      style="padding-left: 40px"
                      :class="$route.path == child.path ? 'is-active' : ''"
                      @click="routerPush(child.path)"
                    >
                      {{ $t(child.name) }}
                    </li>
                  </template>
                </ul>
              </template>
              <template v-else>
                <li class="el-submenu">
                  <div
                    class="el-submenu__title el-menu-item"
                    style="
                      padding-left: 20px;
                      height: 56px;
                      line-height: 56px;
                      padding: 0 20px;
                    "
                    :class="
                      $route.path == item.children[0].path ? 'is-active' : ''
                    "
                    @click="routerPush(item.children[0].path)"
                  >
                    <i :class="item.iconCls"></i>
                  </div>
                </li>
              </template>
            </li>
          </template>
        </ul>
      </aside>
      <section class="content-container">
        <div class="grid-content bg-purple-light">
          <el-col :span="24" class="breadcrumb-container">
            <el-breadcrumb separator="/" class="breadcrumb-inner">
              <el-breadcrumb-item
                v-for="item in $route.matched"
                :key="item.path"
              >
                {{ $t(item.name) }}
              </el-breadcrumb-item>
            </el-breadcrumb>
            <strong class="title">{{ $t($route.name) }}</strong>
          </el-col>
          <el-col :span="24" class="content-wrapper">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
            <!-- <Footer /> -->
          </el-col>
        </div>
      </section>
    </el-col>
  </el-row>
</template>

<script>
// import Footer from "@/components/Footer";
import ChangeLang from "@/components/changeLang";
export default {
  components: {
    ChangeLang,
    // Footer,
  },
  data() {
    return {
      sysName: this.$t("Home.sysName"),
      collapsed: false,
      sysUserName: localStorage.getItem("username"),
    };
  },
  created() {
    this.getPermission();
    if (this.$isMobile) {
      this.collapse();
    }
  },
  methods: {
    getPermission() {
      let permission = 1;
      this.$router.options.routes.forEach((item) => {
        if (item.meta.isPermission.includes(permission)) {
          item.hidden = false;
        }
      });
    },
    onSubmit() {
      console.log("submit!");
    },
    handleopen(e) {
      //console.log('handleopen');
    },
    handleclose() {
      //console.log('handleclose');
    },
    handleselect: function (a, b) {
      if (this.$isMobile) {
        this.collapse();
      }
    },
    //退出登录
    logout: function () {
      var _this = this;
      _this
        .$confirm(this.$t("Home.confirmExit"), this.$t("Home.remain"), {
          type: "warning",
        })
        .then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          _this.$router.push("/login");
        })
        .catch(() => {});
    },
    //折叠导航栏
    collapse: function () {
      this.collapsed = !this.collapsed;
      var menuWidth = document.getElementById("menuClass");
      if (menuWidth && menuWidth.offsetWidth === 0) {
        menuWidth.style.width = "230px";
      }
      this.$nextTick(() => {
        var menuCollapsed = document.querySelector(".menu-collapsed");
        if (menuCollapsed) {
          menuCollapsed.style.display = this.$isMobile ? "none" : "block";
        }
        var menuExpanded = document.querySelector(".menu-expanded");
        if (menuExpanded) {
          menuExpanded.style.display = "block";
        }
      });
    },
    showMenu(i, status) {
      this.$refs.menuCollapsed.getElementsByClassName(
        "submenu-hook-" + i
      )[0].style.display = status ? "block" : "none";
    },
    routerPush(url) {
      // this.$router.push(url)
    },
  },
};
</script>

<style>
.el-message-box {
  max-width: 90vw; /* 设置最大宽度 */
}

.el-message-box__content {
  max-width: 90%; /* 设置内容最大宽度 */
}
</style>
<style scoped lang="less">
.container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  .header {
    height: 60px;
    line-height: 60px;
    background: #20a0ff;
    color: #fff;
    .logout {
      cursor: pointer;
    }
    .userinfo {
      display: flex;
      justify-content: right;
      text-align: right;
      padding-right: 35px;
      float: right;
      .userinfo-inner {
        cursor: pointer;
        color: #fff;
        img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          margin: 10px 10px 10px 0px;
          float: left;
        }
      }
    }
    .logo {
      //width:230px;
      height: 60px;
      font-size: 22px;
      padding-left: 20px;
      padding-right: 20px;
      border-color: rgba(238, 241, 146, 0.3);
      border-right-width: 1px;
      border-right-style: solid;
      img {
        width: 40px;
        float: left;
        margin: 10px 10px 10px 18px;
      }
      .txt {
        color: #fff;
      }
    }
    .logo-width {
      width: 230px;
    }
    .logo-collapse-width {
      width: 60px;
    }
    .tools {
      padding: 0px 23px;
      width: 14px;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
    }
  }
  .main {
    display: flex;
    // background: #324057;
    position: absolute;
    top: 60px;
    bottom: 0px;
    overflow: hidden;
    aside {
      flex: 0 0 60px;
      width: 230px;
      // position: absolute;
      // top: 0px;
      // bottom: 0px;
      .el-menu {
        height: 100%;
      }
      .collapsed {
        width: 60px;
        .item {
          position: relative;
        }
        .submenu {
          position: absolute;
          top: 0px;
          left: 60px;
          z-index: 99999;
          height: auto;
          display: none;
        }
      }
    }
    .menu-collapsed {
      flex: 0 0 60px;
      width: 60px;
    }
    .menu-expanded {
      flex: 0 0 230px;
      width: 230px;
      // position: fixed;
      // height: 100vh;
      // z-index: 9999 !important;
    }
    .content-container {
      // background: #f1f2f7;
      flex: 1;
      // position: absolute;
      // right: 0px;
      // top: 0px;
      // bottom: 0px;
      // left: 230px;
      // overflow-y: scroll;
      overflow: auto;
      padding: 20px;
      .breadcrumb-container {
        //margin-bottom: 15px;
        .title {
          width: 100%;
          float: left;
          color: #475669;
          font-size: 18px;
          margin-top: 20px;
        }
        // .breadcrumb-inner {
        //   float: right;
        // }
      }
      .content-wrapper {
        background-color: #fff;
        box-sizing: border-box;
      }
    }
  }
}
.el-dropdown {
  width: 185px;
}
.sysUserName {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1024px) {
  .menu-expanded {
    position: fixed;
    height: 100vh;
    z-index: 100 !important;
  }
}
</style>
