<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      lastWidth: window.innerWidth, // 初始化 lastWidth
    };
  },
  components: {},
  created() {
    if (window.location.search.includes("fbclid")) {
      window.location.href =
        window.location.origin +
        window.location.pathname +
        window.location.hash;
    }
  },
  mounted() {
    // 添加 resize 事件监听器
    window.addEventListener("resize", this.checkResize);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    window.removeEventListener("resize", this.checkResize);
  },
  methods: {
    checkResize() {
      if (window.innerWidth !== this.lastWidth) {
        this.lastWidth = window.innerWidth; // 更新 lastWidth
        location.reload(); // 刷新页面
      }
    },
  },
};
</script>

<style lang="less">
body {
  margin: 0px;
  padding: 0px;
  /*background: url(assets/bg1.jpg) center !important;
		background-size: cover;*/
  // background: #1F2D3D;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, SimSun, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}
a {
  text-decoration: none;
}
input,
textarea {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #dddddd;
}

::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777777;
}

#app {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.el-submenu [class^="fa"] {
  vertical-align: baseline;
  margin-right: 10px;
}

.el-menu-item [class^="fa"] {
  vertical-align: baseline;
  margin-right: 10px;
}

.toolbar {
  background: #f2f2f2;
  padding: 10px;
  //border:1px solid #dfe6ec;
  margin: 10px 0px;
  .el-form-item {
    margin-bottom: 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
