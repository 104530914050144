<template>
  <section class="main_contain">
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filters">
        <el-form-item :label="$t('table.decProblem')">
          <el-input
            v-model="filters.description"
            :placeholder="$t('table.decProblem')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('table.status')">
          <el-select
            v-model="filters.status"
            :placeholder="$t('table.statusPlaceholder')"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in status"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('table.problemStyle')">
          <el-select
            v-model="filters.type"
            :placeholder="$t('table.problemStyleplaceholder')"
          >
            <el-option :label="$t('table.unusualLogin')" value="1"></el-option>
            <el-option
              :label="$t('table.unusualAccount')"
              value="2"
            ></el-option>
            <el-option :label="$t('table.unusualNap')" value="3"></el-option>
            <el-option :label="$t('table.unusualOther')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <!-- <div style="margin-left: 100px"> -->
        <el-form-item>
          <el-button type="primary" @click="getUsers">{{
            $t("table.search")
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reset">{{
            $t("table.Reset")
          }}</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item> -->
        <!-- <el-form-item>
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-form-item> -->
        <!-- </div> -->
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      id="mainTable"
      :data="ticket_info"
      highlight-current-row
      v-loading="listLoading"
      :empty-text="$t('table.noData')"
      style="width: 100%"
      v-if="$isMobile"
    >
      <el-table-column prop="type" :label="$t('table.problemStyle')" width="100">
        <template slot-scope="scope">
          {{ $t(tableType[scope.row.type]) }}
        </template>
      </el-table-column>
      <el-table-column prop="description" :label="$t('table.decProblem')">
      </el-table-column>
      <!-- 0 工单回复 1 重新开启工单/关闭工单 2 评价（是否已经评价过） -->
      <el-table-column :label="$t('table.operate')" align="left" width="120">
        <template slot-scope="scope">
          <el-button
            @click="handleStart(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 1"
          >
            {{ $t("table.reOpenTicket") }}
          </el-button>
          <el-button
            @click="handleClose(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 1 || scope.row.status == 0"
          >
            {{ $t("table.closeTicket") }}
          </el-button>

          <el-button
            @click="handleReply(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 0"
          >
            {{ $t("table.replyTicket") }}
          </el-button>
          <el-button
            @click="handleComment(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 2 && scope.row.grade == 0"
          >
            {{ $t("table.evaluate") }}
          </el-button>
          <el-button @click="handleClick(scope.row)" type="text" size="small">
            {{ $t("table.feedback") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      id="mainTable"
      :data="ticket_info"
      highlight-current-row
      v-loading="listLoading"
      :empty-text="$t('table.noData')"
      style="width: 100%"
      v-else
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="60"> </el-table-column> -->
      <el-table-column prop="game_name" label="Game" width="150">
      </el-table-column>
      <el-table-column prop="server_name" label="Server"> </el-table-column>
      <el-table-column prop="role_name" :label="$t('table.role')">
      </el-table-column>
      <el-table-column prop="type_list" :label="$t('table.problemStyle')">
        <template slot-scope="scope">
          {{ $t(scope.row.type_list) }}
        </template>
      </el-table-column>
      <el-table-column prop="description" :label="$t('table.decProblem')">
      </el-table-column>
      <!-- <el-table-column prop="mch_url" label="mch_url"> </el-table-column>
      <el-table-column prop="callback_url" label="callback_url">
      </el-table-column> -->
      <el-table-column prop="contact" :label="$t('table.contact')">
      </el-table-column>
      <el-table-column prop="grade" :label="$t('table.star')" width="180">
        <template slot-scope="scope">
          <el-rate
            v-model="scope.row.grade"
            disabled
            show-score
            text-color="#ff9900"
          ></el-rate>
        </template>
      </el-table-column>
      <el-table-column prop="imgUrl" :label="$t('table.img')">
        <template slot-scope="scope">
          <el-image
            v-for="(item, index) in scope.row.imgUrl"
            :key="index"
            :src="item"
            :preview-src-list="[item]"
            style="width: 50px; height: 50px"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="videoUrl" :label="$t('table.video')">
        <template slot-scope="scope" v-if="scope.row.videoUrl">
          <video width="60" height="100" controls>
            <source :src="scope.row.videoUrl" type="video/mp4" />
            {{ $t("table.noUseVideo") }}
          </video>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="created_at" label="Thời gian tạo">
      </el-table-column>
      <el-table-column prop="updated_at" label="Thời gian cập nhật">
      </el-table-column> -->
      <!-- 0 工单回复 1 重新开启工单/关闭工单 2 评价（是否已经评价过） -->
      <el-table-column :label="$t('table.operate')" width="300">
        <template slot-scope="scope">
          <el-button
            @click="handleStart(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 1"
          >
            {{ $t("table.reOpenTicket") }}
          </el-button>
          <el-button
            @click="handleClose(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 1 || scope.row.status == 0"
          >
            {{ $t("table.closeTicket") }}
          </el-button>

          <el-button
            @click="handleReply(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 0"
          >
            {{ $t("table.replyTicket") }}
          </el-button>
          <el-button
            @click="handleComment(scope.row)"
            type="text"
            size="small"
            v-if="scope.row.status == 2 && scope.row.grade == 0"
          >
            {{ $t("table.evaluate") }}
          </el-button>
          <el-button @click="handleClick(scope.row)" type="text" size="small">
            {{ $t("table.feedback") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next,total"
        @current-change="handleCurrentChange"
        :page-size="20"
        :total="total"
        style="float: right"
      >
      </el-pagination>
    </el-col>
    <replyDialog ref="replyDialog" />
    <starDialog ref="starDialog" />
    <userReplyDialog ref="userReplyDialog" />
  </section>
</template>

<script>
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  ticketList,
  ticketCloseApi,
  restartTicketApi,
  ticketReplyLogApi,
} from "@/utils/api";
import replyDialog from "./component/replyDialog";
import starDialog from "./component/starDialog";
import userReplyDialog from "./component/userReplyDialog";
export default {
  components: {
    replyDialog,
    starDialog,
    userReplyDialog,
  },
  data() {
    return {
      filters: {
        description: "",
        status: "",
        type: "",
      },
      tableType: {
        1: "table.unusualLogin",
        2: "table.unusualAccount",
        3: "table.unusualNap",
        4: "table.unusualOther",
      },
      ticket_info: [],
      total: 0,
      page: 1,
      listLoading: false,
      sels: [], //列表选中列
      token: localStorage.getItem("token"),
    };
  },
  computed: {
    status() {
      return [
        {
          value: 0,
          label: this.$t("table.Processing"), //处理中
        },
        {
          value: 1,
          label: this.$t("table.Processed"), //已处理
        },
        {
          value: 2,
          label: this.$t("table.closed"), //已关闭
        },
      ];
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getUsers();
    },
    //获取用户列表
    getUsers() {
      let type_list = {
        1: "table.unusualLogin",
        2: "table.unusualAccount",
        3: "table.unusualNap",
        4: "table.unusualOther",
      };
      let para = {
        page: this.page,
        token: this.token,
        ...this.filters,
      };
      this.listLoading = true;
      ticketList(para).then((res) => {
        if (res.code === 200) {
          this.total = res.data.total;
          this.ticket_info = res.data.list;
          this.ticket_info.forEach((item) => {
            item.type_list = type_list[item.type];
          });
          this.listLoading = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //查看客服回复
    handleClick(data) {
      let params = {
        token: this.token,
        ticket_id: data.id,
      };
      ticketReplyLogApi(params).then((res) => {
        if (res.code === 200) {
          this.$refs.replyDialog.dialogVisible = true;
          this.$refs.replyDialog.replyData = res.data;
        }
      });
    },
    //客服评分
    handleComment(data) {
      this.$refs.starDialog.dialogVisible = true;
      this.$refs.starDialog.ruleForm.grade = data.grade;
      this.$refs.starDialog.ruleForm.comment = data.comment;
      this.$refs.starDialog.starData = data;
    },
    reset() {
      this.filters = {
        description: "",
        status: "",
        type: "",
      };
      this.getUsers();
    },
    handleExport() {
      let url = "https://paym.muugamevn.com/api/exportOrder?";
      // unix()
      let start_time = "";
      let end_time = "";
      if (this.date) {
        start_time = this.$dayjs(this.date[0]).unix();
        end_time = this.$dayjs(this.date[1]).unix();
      }
      let filters = {
        ...this.filters,
        start_time,
        end_time,
        token: this.token,
      };
      for (const key in filters) {
        url = url + key + "=" + filters[key] + "&";
      }
      url = url.slice(0, -1);
      window.location.href = url;
      // this.exportExcel("列表", "mainTable");
    },
    exportExcel(filename, tableId) {
      var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector("#" + tableId).cloneNode(true);
      // console.log(table);
      // table.removeChild(table.querySelector(".el-table__fixed-right"));
      var wb = XLSX.utils.table_to_book(table, xlsxParam);
      /* 获取二进制字符进行输出 */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          filename + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    //开启工单
    handleStart(data) {
      this.$confirm(
        this.$t("table.confirmOpenTicket"),
        this.$t("table.remail"),
        {
          type: "warning",
        }
      ).then(() => {
        let params = {
          ticket_id: data.id,
          token: this.token,
        };

        restartTicketApi(params).then((res) => {
          if (res.code === 200) {
            this.getUsers();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //关闭工单
    handleClose(data) {
      this.$confirm(
        this.$t("table.confirmCloseTicket"),
        this.$t("table.remail"),
        {
          type: "warning",
        }
      ).then(() => {
        let params = {
          ticket_id: data.id,
          token: this.token,
        };

        ticketCloseApi(params).then((res) => {
          if (res.code === 200) {
            this.getUsers();
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //工单回复
    handleReply(data) {
      this.$refs.userReplyDialog.ticket_id = data.id;
      this.$refs.userReplyDialog.dialogVisible = true;
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped lang="less">
// .main_contain {
//   height: 620px;
// }
// @media screen and (max-width: 1000px) {
//   .main_contain {
//     height: 520px;
//   }
// }
@media screen and (max-width: 1024px) {
  .el-select,
  .el-input {
    width: 80vw;
  }

  .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
    margin-left: 0;
  }
}
</style>
